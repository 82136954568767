.generator-picker-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.generator-picker-list {
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
}
.generator-picker-list li {
    width: 100%;
    margin: 0.5rem 0;
}
.generator-picker-list li button {
    width: 100%;
    text-align: left;
}