.btn {
    padding: 0.5em;
    border: none;
    border-radius: 4px;
    user-select: none;
    background: linear-gradient(
        0deg,
        hsla(0, 0%, 6.67%, 1) 0%,
        hsla(0, 0%, 73.33%, 1) 2%,
        hsla(0, 0%, 86.67%, 1) 100%
    );
    color: #333;
    min-width: 1.8rem;
    &:hover:not(:disabled) {
        cursor: pointer;
        background: linear-gradient(
            0deg,
            hsla(0, 0%, 5%, 1) 0%,
            hsla(0, 0%, 68%, 1) 2%,
            hsla(0, 0%, 84%, 1) 100%
        );
    }
    &:disabled {
        opacity: 0.4;
        filter: grayscale(1);
    }
}
.btn-delete {
    background: linear-gradient(
        180deg,
        hsla(243, 100%, 7%, 1) 0%,
        hsla(12, 84%, 43%, 1) 1%,
        hsla(9, 94%, 37%, 1) 100%
    );
    color: rgba(255, 255, 255, 0.8);
    &:hover:not(:disabled) {
        background: linear-gradient(
            180deg,
            hsla(243, 100%, 6%, 1) 0%,
            hsla(12, 84%, 38%, 1) 1%,
            hsla(9, 94%, 33%, 1) 100%
        );
    }
}
.btn-add {
    background: linear-gradient(
        180deg,
        hsla(243, 100%, 7%, 1) 0%,
        hsla(103, 81%, 40%, 1) 1%,
        hsla(103, 80%, 34%, 1) 100%
    );
    color: rgba(255, 255, 255, 0.8);
    &:hover:not(:disabled) {
        background: linear-gradient(
            180deg,
            hsla(243, 100%, 6%, 1) 0%,
            hsla(103, 81%, 35%, 1) 1%,
            hsla(103, 80%, 30%, 1) 100%
        );
    }
}
