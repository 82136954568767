.tutor-free-practice-preview {
    max-width: 480px;
    margin-top: 32px;
    background: white;
    color: rgba(0, 0, 0, 0.87);
    border-radius: 16px;
    padding: 16px;
    font-family: "DM Sans",Helvetica,Arial,sans-serif;
    font-size: 21px;
    text-align: center;
    box-shadow: 0 -4px 12px rgba(91, 151, 185, 0.08);
    &.action-steps ol {
        list-style: none;
        text-align: left;
        margin: 2em auto;
        width: fit-content;
        & li {
            margin: 1em 0;
            display: flex;
            align-items: center;
            gap: 6px;
            & svg {
                color: #23942B;
            }
        }
    }
    & > ol {
        margin: 0;
        padding: 0 32px;
        & .question-viewer-step {
            background: transparent;
            margin: 16px 0;
            padding: 0;
            & .question-viewer-item {
                margin: 8px 0;
                padding: 0;
                & .keyItem {
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
        & > li {
            margin: 0;
            padding: 0;
        }
    }
    & .tutor-free-practice-controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 48px 16px;
        & .question-feedback {
            font-size: 13px;
            margin: 8px 0 16px;
            padding: 0;
            color: rgba(0, 0, 0, 0.67);
            &.incorrect {
                color: #E31B0C;
            }
            &.correct {
                color: #23942B;
            }
        }
    }
    & .tutor-free-practice-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        & button {
            max-width: 50%;
            flex: 1;
            background: transparent;
            text-transform: uppercase;
            border-radius: 8px;
            padding: 12px 0;
            &.outlined {
                border: 1px solid #23942B;
                color: #23942B;
                &:hover {
                    background-color: rgba(35, 148, 43, 0.12);
                    cursor: pointer;
                }
            }
            &.contained {
                background: #23942B;
                color: #fff;
                &:disabled {
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }
                &:hover:not(:disabled) {
                    background-color: #006100;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
    & .answer-input-message {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.67);
    }
}