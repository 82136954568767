.spec-header .icon-title {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    & svg {
        font-size: 300%;
        margin-right: 0.5rem;
    }
    & h2 {
        margin: 0;
    }
    & h3 {
        margin: 0 0 0 2px;
        font-weight: normal;
    }
}