.value-details input,
.value-details select {
    background: white;
}
.value-details-heading {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 1rem;
    letter-spacing: 0.5px;
}
.value-details-heading .value-name-box {
    border: 1px solid;
    padding: 4px 0.5rem;
    border-radius: 4px;
}
.value-details-heading .type-value {
    border-color: var(--primary);
    background: var(--primary-100);
    & svg {
        color: var(--primary);
    }
}
.value-details-heading .type-folder {
    border-color: var(--secondary);
    background: var(--secondary-100);
    & svg {
        color: var(--secondary);
    }
}
.value-details-heading svg {
    margin-right: 4px;
}
.value-name-and-type {
    width: 100%;
    text-align: left;
}
.value-name-and-type th {
    width: 33%;
}
.value-name-and-type th,
.value-name-and-type td {
    background: var(--primary-100);
    border-radius: 8px;
    padding: 0.5rem 1rem;
    height: 2rem;
}
.value-name-and-type.type-folder th,
 .value-name-and-type.type-folder td {
     background: var(--secondary-100);
 }