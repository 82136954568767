.spec-list-wrapper td:has(a) {
    padding: 0;
}
.spec-list-wrapper a {
    color: inherit;
    text-decoration: none;
    padding: 1rem;
    display: block;
}
.spec-list-wrapper td:first-child a:hover {
    text-decoration: underline;
}