.text-area {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: #f9f9f9;
    resize: none;
    padding: 1rem;
    box-sizing: border-box;
    font-family: inherit;
    font-size: 1rem;
}
