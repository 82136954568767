.spec-list-nav.nav-filters .nav-section:has(> div) {
    flex-direction: row;
    gap: 0.3rem;
    display: flex;
}
.spec-list-nav.nav-filters .nav-section h4 {
    margin: 0 0 0.3em;
    color: var(--tertiary);
}
.spec-list-nav.nav-filters .nav-section > div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    flex-grow: 1;
}
.spec-list-nav.nav-filters .spec-search .controls {
    margin-bottom: 0;
    text-align: center;
}
.spec-list-nav.nav-filters .spec-search input {
    width: 100%;
}
.spec-list-nav.nav-filters .nav-section > div select {
    width: 100%;
}
.nav-filters .nav-section a svg {
    color: var(--tertiary);
}