.nav-filters {
    padding: 1rem 0 1rem 1rem;
    margin: 0;
    & h3 {
        margin: 0.5rem 0.5rem -0.5rem 0.5rem;
        color: var(--tertiary);
        font-size: 100%;
        & .btn {
            background: none;
            padding: 4px;
            font-size: 1rem;
            & .fa-folder-closed {
                color: var(--secondary);
            }
            & .fa-chart-simple {
                color: var(--primary);
            }
            &:not(:hover) svg {
                color: var(--tertiary);
            }
            &:hover {
                background: none;
            }
        }
    }
    --folder-depth: 0;
    --folder-depth-unit: 26px;
}
.nav-filters .nav-section {
    margin-top: 1rem;
    background: rgba(255, 255, 255, 0.8);
    padding: 1rem 1rem 1rem 0.5rem;
    border-radius: 12px 0 0 12px;
    box-shadow: -1px -1px 5px rgba(0, 0, 0, 0.1);
}
.nav-filters li {
    margin: 5px 0;
    padding: 0;
    border: 1px solid transparent;
    & .sortable-nav-item-wrapper {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-left: calc(var(--folder-depth) * var(--folder-depth-unit));
    }
    & .sortable-nav-item-wrapper > span,
    & .sortable-nav-item-wrapper > fieldset {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        &:not(.group-controls) {
            flex: 1;
        }
        &.group-controls {
            visibility: hidden;
            border: none;
            & button {
                padding: 4px;
                margin: 0;
                min-width: auto;
                & svg {
                    padding:0;
                    width: 1rem;
                    color: rgba(0, 0, 0, 0.3);
                }
                &:hover {
                    cursor: pointer;
                    & svg {
                        color: var(--primary);
                    }
                    & .fa-folder-closed {
                        color: var(--secondary);
                    }
                    & .fa-chart-simple {
                        color: var(--primary);
                    }
                }
            }
            &:hover button {
                visibility: visible;
            }
        }
        & a {
            flex: 1;
            font-size: 1rem;
        }
        & button {
            padding: 0.25rem;
            font-size: 1rem;
            background: none;
            &:hover {
                background: none;
                cursor: move;
            }
        }
    }
    &:hover .sortable-nav-item-wrapper > .group-controls {
        visibility: visible;
    }
    & .sortable-nav-folder-wrapper {
        & .fa-folder-closed {
            color: var(--secondary);
        }
        & .fa-folder-open {
            color: var(--secondary);
        }
    }
    &.drop-folder {
        border: 1px solid var(--secondary);
    }
    &:has(.active):not(.drop-folder):not(.step-folder) {
        background: var(--tertiary-100);
        border: 1px dashed var(--tertiary-900);
        border-radius: 4px;
    }
}
.nav-filters li.sortable-nav-item {
    margin: 2px 0;
    & a {
        padding: 3px 0.5rem 3px 0.2rem;
        flex: 1;
        font-size: 1rem;
    }
    & .sortable-nav-folder-wrapper {
        padding: 0;
    }
}
.nav-filters a {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 0.5rem 0.5rem 0.2rem;
    text-decoration: none;
    color: var(--tertiary-900);
    border-radius: 4px;
    cursor: pointer;
}
.nav-filters a svg {
    width: 1.5rem;
    margin-right: 0.25rem;
}
.nav-filters a span:nth-child(2) {
    background: #ddd;
    padding: 2px 0.5rem;
    border-radius: 1rem;
    font-size: 90%;
    min-width: 1rem;
    text-align: center;
}
.nav-filters li:has(:hover) {
    color: black;
    background: var(--tertiary-100);
}
.nav-filters svg {
    width: 1.1rem;
}
.nav-filters .value-item {
    padding: 0 3px;
    & svg {
        width: 1.5rem;
        font-size: 1rem;
    }
}
.step-items svg {
    color: var(--primary);
}