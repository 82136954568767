.spec-list-wrapper {
    width: auto;
    margin: 0.5rem 0 2rem;
}
.spec-list-wrapper .rich-authoring-table {
    border-collapse: collapse;
}
.spec-list-wrapper .rich-authoring-table th,
.spec-list-wrapper .rich-authoring-table th {
    text-align: left;
}
.spec-list-wrapper .rich-authoring-table th {
    font-weight: bold;
    color: rgba(0, 0, 0, 0.8);
    border-bottom: 1px solid black;
    border-top: 1px solid black;
    padding-bottom: 0.5rem;
    padding-top: 0.5rem;
}
@keyframes spinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}
.pagination-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 0.5rem 0.5rem;
    & svg.is-previous-data {
        animation: spinner 1.2s linear infinite;
    }
}
.pagination-controls .btn {
    margin-left: 6px;
}
.pagination-controls select {
    padding: 6px 0.5rem;
}
.spec-list-filters {
    display: flex;
    justify-content: space-around;
    min-width: 900px;
    margin: 2rem 0;
}
.spec-list-filters > span {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}
a.spec-list-info, a.usage-check {
    border-radius: 2rem;
    width: 1rem;
    padding: 1rem;
    display: inline-block;
}
a.spec-list-info:hover, a.usage-check:hover {
    color: rgba(255, 255, 255, 0.6);
    background: rgba(0, 0, 0, 0.3);
}
.btn-delete:hover {
    cursor: pointer;
}
.spec-list-wrapper .no-items {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #222;
    color: rgba(255, 255, 255, 0.6);
    padding: 3rem 1rem;
    border-radius: 8px;
    border: 1px dashed rgba(255, 255, 255, 0.6);
}
.spec-list-wrapper td.specs-list-actions {
    width: 6rem;
    padding: 0 0.5rem;
}
td.specs-list-actions p {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.spec-list-wrapper tr:hover td {
    background: #f9f9f9;
}
.specs-list-actions p a {
    padding: 0.5rem;
    opacity: 0.1;
}
.spec-list-wrapper tr:hover .specs-list-actions a {
    opacity: 0.5;
}
.specs-list-actions p a:hover {
    opacity: 1;
}