.generator-preview {
    text-align: center;
}
.generated-value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: var(--primary-100);
    color: var(--primary);
    padding: 1rem;
    margin: 0.5rem 0;
    border-radius: 8px;
    border: 1px solid transparent;
}
.generated-value-compound > .generated-value {
    background: var(--secondary-100);
    color: var(--secondary);
}
.generated-value-compound .toggle-content {
    padding: 0.5rem 0 0 1.5rem;
    margin: -1rem 0 0.5rem;
}
.generated-value-compound table {
    width: 100%;
}
.generated-value-compound th,
.generated-value-compound td {
    border-top: 1px solid #ccc;
    padding: 0.3rem 0;
}
.generator-preview .snapshot-btn-refresh {
    border: none;
    padding: 0.5rem 1rem;
    margin: 1rem 0;
    font-size: 100%;
    border-radius: 8px;
}
.generator-preview .snapshot-btn-refresh:hover {
    cursor: pointer;
    background: #ddd;
}
.toggle-error > .generated-value {
    border: 1px solid var(--warning);
    background: var(--warning-100);
    color: var(--warning);
}