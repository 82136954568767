.rich-authoring-table {
    width: 100%;
    transition: opacity 300ms ease-in-out;
    &.is-previous-data {
        opacity: 0.6;
    }
}
.rich-authoring-table th,
.rich-authoring-table td {
    padding: 1rem;
    color: rgba(0, 0, 0, 0.6);
    height: 2rem;
    background: white;
    border-top: 1px solid #eee;
    text-align: left;
}