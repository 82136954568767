.manipulatives-text-box {
    padding: 0.5rem;
    margin: 0;
    background: #f9f9f9;
    color: inherit;
    border: 1px solid #ccc;
    border-radius: 4px;
}
.manipulatives-text-box-longer {
    width: 15rem;
}
.manipulatives-text-box-longest {
    width: 25rem;
}
