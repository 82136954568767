.generator-values {
    font-size: 80%;
    & button {
        padding: 0;
        border: none;
    }
    & svg {
        width: 1.5rem;
        color: darkorange;
    }
}
.values-list-controls {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.generator-values .value-item {
    padding: 0 3px;
    border-radius: 4px;
    font-size: 90%;
}
.generator-values .value-item:hover .value-name,
.generator-values .value-item:hover .folder-name {
    background: #ddd;
    cursor: pointer;
}
.generator-values .value-item h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 4px;
    border: 1px solid transparent;
    border-radius: 4px;
    margin: 0 0 0 calc(var(--folder-depth) * 24px);
    padding: 0 0 0 2px;
}
.generator-values .folder-name span:first-child svg {
    color: skyblue;
}
.generator-values .drop-folder h3 {
    border: 1px solid skyblue;
}
.generator-values .value-item h3 .group-controls {
    opacity: 0;
    & button {
        background: transparent;
        padding: 4px;
        min-width: 1em;
        margin: 0 1px;
        & svg {
            color: rgba(0, 0, 0, 0.4);
        }
    }
    & button:hover svg {
        color: skyblue;
    }
    & button:first-child:hover svg {
        color: darkorange;
    }
    & button:hover {
        cursor: pointer;
        background: rgba(255, 255, 255, 0.1);
    }
}
.generator-values .value-item:hover .group-controls {
    opacity: 1;
}
.generator-values .folder-name,
.generator-values .value-name {
    flex: 1;
    margin: 0;
    font-weight: normal;
    & span:first-child button {
        background: transparent;
        padding: 4px;
        min-width: 1em;
        margin: 0 1px;
    }
}
.generator-preview-panel h3 {
    text-align: center;
}