.value-rules > h3 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 3rem;
}
.value-rules fieldset {
    border: none;
    padding: 0;
    margin: 0;
}