.multi-tree {
    color: rgba(0, 0, 0, 0.6);
    background: #eee;
    width: 20em;
    overflow-x: scroll;
    padding: 1em;
    font-family: 'Helvetica', sans-serif;
    font-size: 80%;
    flex-grow: 0;
    flex-shrink: 0;
    word-break: break-word;
    & button {
        padding: 0;
        background: transparent;
        color: inherit;
        border: none;
    }
    & .multi-tree-section {
        &:not(:first-child) {
            margin-top: 3em;
        }
    }
    & h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        margin: 1em 0 0;
        border-radius: 4px;
        & span:first-child {
            flex: 1;
            display: flex;
            & button {
                flex: 1;
                text-align: left;
            }
        }
        & span:last-child {
            opacity: 0;
        }
        &:hover {
            background: rgba(0, 0, 0, 0.1);
            & span:last-child {
                opacity: 1;
            }
        }
        & span:last-child button {
            padding: 4px;
            & svg {
                color: rgba(255, 255, 255, 0.4);
            }
            &:hover {
                background: rgba(255, 255, 255, 0.1);
                & svg {
                    color: darkorange;
                }
            }
        }
    }
}

.multi-tree-section fieldset.step-controls {
    border: none;
}