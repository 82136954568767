.type-picker {
    display: flex;
    & ul {
        list-style: none;
        padding: 0;
        margin: 0;
    }
    & li {
        margin: 3px 0;
        width: 100%;
        opacity: 0.7;
        &.selected {
            opacity: 1;
        }
    }
    & button {
        width: 100%;
        text-align: left;
    }
}
.type-picker-groups {
    width: calc(33%);
    background: var(--primary-100);
    border: 1px solid white;
    border-radius: 8px;
    padding: 0 0 0 0.5rem;
    box-sizing: border-box;
    & :hover {
        opacity: 1;
        cursor: pointer;
    }
    & button {
        background: transparent;
        color: rgba(0, 0, 0, 0.8);
        border: none;
        & svg {
            color: var(--secondary);
            margin-right: 0.4em;
            width: 1em;
        }
    }
}
.type-picker-types {
    width: 66.6666%;
    padding: 0.5rem 1.1rem;
    background: var(--primary-100);
    border: 1px solid white;
    border-radius: 8px;
    box-sizing: border-box;
    & li {
        margin-bottom: 5px;

        & button {
            padding: 0;
            border-radius: 4px;
            border: 1px dotted var(--primary);
            background: transparent;

            & h3 {
                margin: 0;
                padding: 0.3rem 0.5rem;
                background: var(--primary-200);
                color: rgba(0, 0, 0, 0.8);
                border-radius: 4px 4px 0 0;
            }

            & p {
                margin: 0;
                padding: 0.4rem 0.5rem;
                background: var(--primary-100);
                color: rgba(0, 0, 0, 0.8);
                border-radius: 0 0 4px 4px;
            }
        }
        &.selected {
            transform: scale(1.02);
        }
        &:hover {
            transform: scale(1.02);
            opacity: 1;
            & button {
                border-style: solid;
            }
        }
    }
}