.rules-table {
    width: 100%;
    margin-top: 1rem;
    border-spacing: 3px;
}
.rules-table th {
    text-align: left;
    width: 33%;
}
.rules-table th,
.rules-table td {
    padding: 0.5rem 1rem;
    background: var(--secondary-100);
    border-radius: 8px;
    color: rgba(0, 0, 0, 0.6);
}
.rules-table td:last-child {
    text-align: center;
}
.rules-table select,
.rules-table input {
    width: 100%;
    box-sizing: border-box;
    border: 1px solid var(--secondary-200);
}