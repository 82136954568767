@font-face {
    font-family: 'DM Sans';
    font-style: normal;
    font-weight: 400;
    src: url('../../../../styles/fonts/DMSans-Regular.ttf');
    src: local(''), url('../../../../styles/fonts/DMSans-Regular.ttf') format('truetype');
}

.combined-editor .multi-tree {
    color: rgba(0, 0, 0, 0.6);
    background: #eee;
    width: 20em;
    overflow-x: scroll;
    padding: 1em;
    font-family: 'Helvetica', sans-serif;
    font-size: 80%;
    flex-grow: 0;
    flex-shrink: 0;
    word-break: break-word;
    & button {
        padding: 0;
        background: transparent;
        color: inherit;
    }
    & .multi-tree-section {
        &:not(:first-child) {
            margin-top: 3em;
        }
    }
    & h4 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 4px;
        margin: 1em 0 0;
        border-radius: 4px;
        & span:first-child {
            flex: 1;
            display: flex;
            & button {
                flex: 1;
                text-align: left;
            }
        }
        & span:last-child {
            opacity: 0;
        }
        &:hover {
            background: rgba(0, 0, 0, 0.2);
            & span:last-child {
                opacity: 1;
            }
        }
        & span:last-child button {
            padding: 4px;
            & svg {
                color: rgba(255, 255, 255, 0.4);
            }
            &:hover {
                background: rgba(255, 255, 255, 0.1);
                & svg {
                    color: darkorange;
                }
            }
        }
    }
}
.combined-editor .question-type-preview {
    padding: 1em 2em;
    & h2 {
        display: none;
    }
    & > div {
        min-width: auto;
        width: 100%;
    }
    background: red;
}
.preview-panel:has(.tutor-free-practice-preview) {
    overflow: hidden;
    background: var(--tertiary-100);
    border-radius: 16px;
    box-shadow: -1px -1px 6px rgba(0, 0, 0, 0.2) inset;
    margin-left: 1rem;
}
.tutor-free-practice-preview {
    background: white;
    color: rgba(0, 0, 0, 0.87);
    padding: 16px;
    font-family: "DM Sans",Helvetica,Arial,sans-serif;
    font-size: 21px;
    text-align: center;
    box-shadow: 0 -4px 12px rgba(91, 151, 185, 0.08);
    margin: 0 auto;
    &.action-steps ol {
        list-style: none;
        text-align: left;
        margin: 2em auto;
        width: fit-content;
        & li {
            margin: 1em 0;
            display: flex;
            align-items: center;
            gap: 6px;
            & svg {
                color: #23942B;
            }
        }
    }
    & > ol {
        margin: 0;
        padding: 0 32px;
        & .question-viewer-step {
            background: transparent;
            margin: 16px 0;
            padding: 0;
            & .question-viewer-item {
                margin: 8px 0;
                padding: 0;
                & .keyItem {
                    font-weight: bold;
                    color: rgba(0, 0, 0, 0.87);
                }
            }
        }
        & > li {
            margin: 0;
            padding: 0;
        }
    }
    & .tutor-free-practice-controls {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 0 48px 16px;
        & .question-feedback {
            font-size: 13px;
            margin: 8px 0 16px;
            padding: 0;
            color: rgba(0, 0, 0, 0.67);
            &.incorrect {
                color: #E31B0C;
            }
            &.correct {
                color: #23942B;
            }
        }
    }
    & .tutor-free-practice-buttons {
        width: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        gap: 16px;
        & button {
            max-width: 50%;
            flex: 1;
            background: transparent;
            text-transform: uppercase;
            border-radius: 8px;
            padding: 12px 0;
            &.outlined {
                border: 1px solid #23942B;
                color: #23942B;
                &:hover {
                    background-color: rgba(35, 148, 43, 0.12);
                    cursor: pointer;
                }
            }
            &.contained {
                background: #23942B;
                color: #fff;
                &:disabled {
                    background-color: rgba(0, 0, 0, 0.12);
                    color: rgba(0, 0, 0, 0.26);
                }
                &:hover:not(:disabled) {
                    background-color: #006100;
                    color: #fff;
                    cursor: pointer;
                }
            }
        }
    }
    & .answer-input-message {
        font-size: 13px;
        color: rgba(0, 0, 0, 0.67);
    }
}

.combined-editor .details-panel {
    color: rgba(0, 0, 0, 0.6);
    & tr:nth-child(2n) td {
        background: rgba(255, 255, 255, 0.15);
    }
    & tr:is(.selected) td {
        color: goldenrod;
    }
    & .generator-picker-list {
        height: 60vh;
        overflow-y: scroll;
    }
}
.value-details-heading {
    font-weight: normal;
    font-size: 90%;
    & .step-number {
        background: var(--secondary);
        color: black;
        padding: 4px 8px;
        border-radius: 4px;
        margin-right: 6px;
    }
    & .item-number {
        background: var(--primary);
        color: black;
        padding: 4px 8px;
        border-radius: 4px;
        margin-right: 6px;
    }
    & .item-type {
        border: 1px solid var(--primary);
        padding: 4px 8px 4px 0;
        border-radius: 4px;
        & svg {
            width: 2em;
            color: var(--primary);
        }
    }
}
.tutor-free-practice-wrapper {
    text-align: center;
    & .snapshot-btn-refresh {
        background: white;
        color: rgba(0, 0, 0, 0.57);
        padding: 1em 2em;
        margin: 2em;
        border: 1px solid transparent;
        border-radius: 8px;
        &:hover {
            cursor: pointer;
            border-color: var(--secondary);
        }
    }
}
.generator-picker-filter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 4px;
}
.generator-picker {
    margin-top: 2em;
}
.generator-picker-empty {
    text-align: center;
    padding: 2em;
    border: 1px solid rgba(255, 255, 255, 0.4);
    border-radius: 4px;
}
.generator-picker-list {
    margin: 0;
    padding: 0;
    list-style: none;
}
.generator-details {
    width: 100%;
    border: 1px solid #ccc;
    border-radius: 4px;
    background: transparent;
    color: rgba(0, 0, 0, 0.6);
    text-align: left;
    margin: 4px 0;
    padding: 0;
    & h3 {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #eee;
        padding: 8px 0.5rem;
        margin: 0;
        border-radius: 4px 4px 0 0;
        &:only-child {
            border-radius: 4px;
        }
        & a {
            color: inherit;
            text-decoration: none;
            font-size: 90%;
            font-weight: normal;
        }
    }
    & p {
        background: #f9f9f9;
        padding: 8px 0.5rem;
        margin: 0;
        border-radius: 0 0 4px 4px;
    }
    &:is(button):hover {
        color: rgba(0, 0, 0, 1);
        border-color: inherit;
        cursor: pointer;
    }
    &.selected {
        color: rgba(0, 0, 0, 0.8);
    }
}

.view-props-table {
    margin-top: 2rem;
}