@import 'styles/style-vars.css';

html {
    font-size: 16px;
    background: #111;
    font-family: "DM Sans", Helvetica, Arial, sans-serif;
    color: rgba(0, 0, 0, 0.6);
}
body {
    min-width: 100%;
    min-height: 100vh;
    margin: 0;
    padding: 0;
}
#root > header {
}
#root > section  {
    display: flex;
    min-height: 100vh;
    margin-top: 4px;
}
#root > section > nav {
    width: 18rem;
    flex-shrink: 0;
    box-sizing: border-box;
    border-radius: 16px 0 0 0;
    background: var(--tertiary-100);
    background-image: var(--grid-image);
    background-position: -2rem 0;
    background-blend-mode: multiply;
    padding-top: 0;
    padding-right: 1rem;
    margin-right: -1rem;
    & .logo-box {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        margin: 1rem 1rem 0 0;
        border-radius: 12px;
        & img {
            padding: 0.5rem;
            background: #111;
            border-radius: 50%;
            width: 6rem;
            height: 6rem;
        }
    }
}
#root > section > main {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    background: var(--tertiary-100);
    background-image: var(--grid-image);
    background-position: -19rem 0;
    background-blend-mode: multiply;
    border-radius: 16px 0 0 0;
}
#root > section > main > header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    & a {
        color: var(--tertiary);
        text-decoration: none;
        padding: 0.5rem;
        border: 1px solid var(--tertiary);
        border-radius: 8px;
        &:hover {
            background: var(--tertiary);
            color: white;
        }
    }
    padding: 1.5rem 2.5rem 1.5rem 1rem;
    color: var(--tertiary);
    & h2 {
        margin: 0;
    }
    & p {
        margin: 0;
    }
}
#root > section > main > div {
    display: flex;
    background: rgba(255, 255, 255, 0.8);
    border-radius: 16px 0 0;
    border: 1px solid var(--tertiary-200);
    flex: 1;
    box-shadow: -1px -1px 15px rgba(0, 0, 0, 0.1);
    padding: 2rem;
}
#root > section > main > div > div {
    width: 50%;
    padding: 0 2rem;
    flex: 1;
}
#root > section > main > div:first-child {
    border-radius: 16px 0 0;
}
ul, ol {
    margin: 0;
    padding: 0;
    list-style: none;
}
.icon-menu-bar {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    flex-shrink: 0;
    margin: 0;
    padding: 1.5rem 0 0;
    list-style: none;
    color: #eee;
    background: #111;
    width: 2.5rem;
    & svg {
        color: #ccc;
    }
    & span {
        color: #eee;
    }
    & ul {
        width: 100%;
        margin: 0;
        padding: 0;
        border-bottom: 1px solid rgba(255, 255, 255, 0.2);
        &:first-child {
            border-top: 1px solid rgba(255, 255, 255, 0.2);
        }
    }
    & li {
        text-align: center;
        opacity: 0.5;
        width: 100%;
        padding: 0;
        & a {
            display: block;
            padding: 0.5rem 0;
        }
    }
    & li:hover {
        opacity: 1;
    }
}
.details-panel fieldset {
    border: none;
    margin: 0;
    padding: 0;
}
.editor-table {
    width: 100%;
    margin-bottom: 1rem;
}
.editor-table th,
.editor-table td {
    padding: 1rem;
    background: var(--primary-100);
    border-radius: 8px;
    text-align: left;
}
.editor-table label {
    display: flex;
    justify-content: space-between;
    align-items: center;
}
.editor-table .item-control-field {
    margin: 1rem;
}