.theme1 {
    --primary: hsl(22, 83%, 58%);
    --primary-100: hsl(22, 83%, 95%);
    --primary-200: hsl(22, 20%, 90%);
    --secondary: hsl(212, 43%, 65%);
    --secondary-100: hsl(212, 43%, 95%);
    --secondary-200: hsl(212, 20%, 90%);
    --tertiary: hsl(270, 48%, 35%);
    --tertiary-100: hsl(270, 20%, 97%);
    --tertiary-200: hsl(270, 20%, 90%);
    --tertiary-900: hsl(270, 48%, 30%);
    --warning: hsl(3, 73%, 38%);
    --warning-100: hsl(3, 73%, 87%);
    --grid-image: url("/public/images/grid.png");
}
.theme2 {
     --primary: hsl(22, 83%, 58%);
     --primary-100: hsl(22, 20%, 97%);
     --secondary: hsl(212, 43%, 65%);
     --secondary-100: hsl(212, 20%, 97%);
     --tertiary: hsl(22, 83%, 58%);
     --tertiary-100: hsl(22, 20%, 97%);
     --tertiary-200: hsl(22, 20%, 90%);
     --tertiary-900: hsl(22, 40%, 20%);
     --warning: hsl(3, 73%, 38%);
    --grid-image: url("/public/images/grid.png");
 }
.theme3 {
    --primary: hsl(22, 83%, 58%);
    --primary-100: hsl(22, 20%, 97%);
    --secondary: hsl(212, 43%, 65%);
    --secondary-100: hsl(212, 20%, 97%);
    --tertiary: hsl(22, 83%, 58%);
    --tertiary-100: hsl(22, 20%, 97%);
    --tertiary-200: hsl(22, 20%, 90%);
    --tertiary-900: hsl(22, 40%, 20%);
    --warning: hsl(3, 73%, 38%);
    --grid-image: none;
}
.theme4 {
    --primary: hsl(22, 83%, 58%);
    --primary-100: hsl(22, 83%, 95%);
    --primary-200: hsl(22, 20%, 90%);
    --secondary: hsl(212, 43%, 65%);
    --secondary-100: hsl(212, 43%, 95%);
    --secondary-200: hsl(212, 20%, 90%);
    --tertiary: hsl(270, 48%, 35%);
    --tertiary-100: hsl(270, 20%, 97%);
    --tertiary-200: hsl(270, 20%, 90%);
    --tertiary-900: hsl(270, 48%, 30%);
    --warning: hsl(3, 73%, 38%);
    --grid-image: none;
}